/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-26 10:59:39
 */
import React from 'react'

export const regExt = {
  phone: /^1[3456789]\d{9}$/,
  mail: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
  account: /^([A-Za-z0-9\.\@\!\#\$\%\^\&\*\(\)]){6,50}$/, // 允许输入6-50位英文、数字、符号，英文不区分大小写
  identity: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  bankNo: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
}

export const storageKey = 'QYTD-LOADDING-SIGN'

export const navMenus = [{
  computerId: '0',
  mobileId: '0',
  name: 'home',
  text: '首页',
}, {
  computerId: '2',
  mobileId: '8',
  name: 'assets',
  text: '数字资产',
}, {
  computerId: '3',
  mobileId: '9',
  name: 'digital',
  text: '数字人',
}, {
  computerId: '4',
  mobileId: '10',
  name: 'infinite',
  text: '无限盒子',
}, {
  computerId: '13',
  mobileId: '14',
  name: 'nft',
  text: '无限数藏',
}, {
  computerId: '1',
  mobileId: '7',
  name: 'product',
  text: '产品技术',
}, {
  computerId: '5',
  mobileId: '11',
  name: 'partner',
  text: '合作支持',
}]

export const mobileSubMenus = [{
  name: 'home',
  text: '首页',
}, {
  name: 'introduce',
  text: '关于我们',
}, {
  name: 'contact',
  text: '联系我们',
}]

export const terminals = [{
  name: 'mobile',
  title: '移动端(Android/ios)',
  intro: <span>无限盒子支持使用移动端体验</span>
}, {
  name: 'pc',
  title: 'PC端',
  intro: <span>无限盒子支持使用PC端体验</span>
}, {
  name: 'vr',
  title: 'VR端',
  intro: <span>无限盒子支持使用<br />VR客户端上的沉浸式VR体验</span>
}]

export const partners = ['aqi', 'aqi', 'aqi', 'aqi', 'aqi']
