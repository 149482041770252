/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-20 21:41:57
 */
/**
 * 回调节流
 *
 * @export
 * @param {*} action 回调
 * @param {*} delay 等待的时间
 * @param {*} context this指针
 * @param {Boolean} iselapsed 是否等待上一次
 * @returns {Function}
 */
export const throttle = (action, delay, context, iselapsed) => {
    let timeout = null;
    let lastRun = 0;
    return function () {
        if (timeout) {
            if (iselapsed) {
                return;
            } else {
                clearTimeout(timeout);
                timeout = null;
            }
            // return;
        }
        let elapsed = Date.now() - lastRun;
        let args = arguments;
        if (iselapsed && elapsed >= delay) {
            runCallback();
        } else {
            timeout = setTimeout(runCallback, delay);
        }
        /**
         * 执行回调
         */
        function runCallback() {
            lastRun = Date.now();
            timeout = false;
            action.apply(context, args);
        }
    };
}

// 处理视频中心数据
export const formatDigitalData = digitalVideos => {
    const videoType1= digitalVideos.filter(item => item.videoType === '1').sort((a, b) => b.weight - a.weight)
    const videoType2= digitalVideos.filter(item => item.videoType === '2').sort((a, b) => b.weight - a.weight)
    const videoType3= digitalVideos.filter(item => item.videoType === '3').sort((a, b) => b.weight - a.weight)
    const data = []
    if (videoType1.length > 0) {
        data.push({
            title: videoType1[0].videoTypeCn,
            videos: videoType1
        })
    }
    if (videoType2.length > 0) {
        data.push({
            title: videoType2[0].videoTypeCn,
            videos: videoType2
        })
    }
    if (videoType3.length > 0) {
        data.push({
            title: videoType3[0].videoTypeCn,
            videos: videoType3
        })
    }
    return data
}

export const openUrl = async (url) => {
    let a = window.document.createElement('a');
    a.href = url;
    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
}
