/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-27 13:54:27
 */
import React, { useState } from 'react'
import { Mask } from 'antd-mobile'
import { openUrl } from '/libs/util'
import './index.less'

function Nft() {
  const [visible, setVisible] = useState(false)
  const ua = navigator.userAgent.toLowerCase() 
  const isIos = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1) //苹果手机
  const isAndroid = (ua.indexOf('android') > -1 || ua.indexOf('linux') > -1) //安卓手机
  const isWeixin = ua.indexOf('micromessenger') > -1 //微信判断
  const isQQ = ua.indexOf('mqqbrowser') > -1 //qq判断

  const handleClick = () => {
    if (isWeixin || isQQ) {
      setVisible(true)
      return
    }
    if (isAndroid) {
      openUrl('/api/public/download')
    } else if (isIos) {
      openUrl('https://apps.apple.com/cn/app/%E6%97%A0%E9%99%90%E6%95%B0%E8%97%8F/id1628776487')
    }
  }

  return (
    <div className="mobile-block nft" id="nft">
      <img className="nft-block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/nft-block-title.png" />
      <div className="nft-content-super">
        <div className="nft-title">一站式【数字藏品】交易平台</div>
        <div className="nft-desc">无限数藏是启元天地旗下数字资产综合服务平台，区块链底层基础设施支撑由数文链提供，为企业提供数字藏品存证、备案、登记、发行、销售等服务。拥有从创意制作到发行销售的完整生态链路及成熟的数字藏品发行经验，可发行作品类型包括但不限于图片、音乐、视频、3D模型等，面向文娱、时尚、地产、体育、消费等多个领域，致力于元宇宙营销和数字经济增长。</div>
        <img className="mobile-nft-main-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/nft-main-img.gif" />
        <div className="btn-super">
          <img 
            className="download-btn" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/nft-download-btn.png" 
            onClick={handleClick}
          />
        </div>
      </div>
      <img className="block-devide-2" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-2.png" />
      <Mask 
        className="nft-tip-model" 
        visible={visible} 
        onMaskClick={() => setVisible(false)}
        getContainer={document.getElementById('app')}
      >
        <div className="share-arrow-super">
          <img className="share-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/share-arrow.png" />
        </div>
        <div className="share-mask-desc">选择“在浏览器打开”</div>
      </Mask>
    </div>
  )
}

export default Nft
