/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-22 17:25:58
 */
import React, { useState, useEffect } from 'react'
import { mobileSubMenus, navMenus } from '/libs/constant'
import { Mask } from 'antd-mobile'
import api from '/api'
import './index.less'

function Introduce({ history, location }) {
  const { state } = location
  const [showMenu, setShowMenu] = useState(false)
  const [menus, setMenus] = useState([])

  useEffect(() => {
    if (state) {
      scrollToAnchor(state.id)
    }
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '2')
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.mobileId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
    })
  }, [])

  const clickMenu = name => {
    switch (name) {
      case 'home': 
        history.push('/mobile', { id: 'home'})
        break
      default:
        scrollToAnchor(name)
        setShowMenu(false)
    }
  }

  const clickNav = id => history.push('/mobile', { id })

  // 滚动到顶部
  const scrollToTop = () => {
    let scrollTimer = setInterval(() => {
      const  scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const ispeed = Math.ceil(scrollTop / 6)
      if(scrollTop <= 0){
        clearInterval(scrollTimer)
        scrollTimer = null
      } else {
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop - ispeed
      }
    },30)
  }

  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }

  return (
    <div className="mobile-container">
      <div className="nav-super">
        <img 
          className="nav-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => history.push('/mobile', { id: 'home'})}
        />
        <img 
          className={`nav-button ${showMenu ? 'close' : ''}`}
          src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/${showMenu ? 'mobile-close-img' : 'nav-button'}.png`}
          onClick={toggleMenu}
        />
      </div>
      <div className="bottom-super">
        <div>定制您的专属方案！</div>
        <div className="contact-button" onClick={() => history.push('/mobile/collection')}>联系我们</div>
      </div>
      <div className="intro-block">
        <div className="block-super" id="introduce">
          <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/intro-block-title.png" />
          <img className="intro-view" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/company-intro-view.png" />
          <div className="text-super">
            <div>北京启元天地网络信息科技有限公司，是北京全时天地在线网络信息股份有限公司全资子公司，成立于2021年11月29日，位于北京城市副中心通州紫光科技园。公司凭借实景影棚、数字影棚、专业级光、动捕设备、360度虚拍系统等多元化虚拟内容制作体系，致力于为企业级客户打造集合虚拟数字资产创造、商业化应用、资产管理、资产增值等可全场景虚拟现实空间无缝链接的商业服务生态闭环。</div>
            <div>未来，启元天地将助力企业在多维场景中打造品牌“新名片”，以内容、技术、人才，以及产业生态共同发力的多边发展格局，以虚拟与现实交互的数智化服务，帮助更多品牌沉淀虚拟数字资产，实现Web3.0时代下的品牌价值升级。</div>
          </div>
        </div>
        <div className="block-super" id="contact">
          <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-block-title.png" />
          <div className="contact-content">
            <div className="title">您也可以通过以下方式联系我们</div>
            <ul className="info-list">
              <li className="info-item">
                <img className="info-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-phone-icon.png" />
                <div>010-53565500</div>
              </li>
              <li className="info-item">
                <img className="info-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-website-icon.png" />
                <div>http://himymeta.com</div>
              </li>
              <li className="info-item">
                <img className="info-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-address-icon.png" />
                <div>北京市通州区商通大道5号院通州紫光科技园21号楼</div>
              </li>
            </ul>
            <div className="qr-list">
              <div className="qr-item">
                <img className="qr" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-gzh.png" />
                <div>微信公众号</div>
              </div>
              <div className="qr-item">
                <img className="qr" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-consultant.png" />
                <div>扫码咨询</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-bottom-nav">
        <ul className="nav-list">
          <li className="nav-item" onClick={() => clickNav('assets')}>
            <div>{menus[1] && menus[1].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('infinite')}>
            <div>{menus[3] && menus[3].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('nft')}>
            <div>{menus[4] && menus[4].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => scrollToAnchor('introduce')}>
            <div>关于我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => scrollToAnchor('contact')}>
            <div>联系我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
        </ul>
      </div>
      <div className="mobile-footer">
        <div className="license-list">
          <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
          <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
            <img 
              className="police-logo" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
            />
            京公网安备 11011202003539号
          </div>
        </div>
        <img 
          className="totop-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-totop-icon.png" 
          onClick={scrollToTop}
        />
      </div>
      <div className="bottom-blank"></div>
      <Mask 
        className="menu-modal-super"
        visible={showMenu} 
        onMaskClick={() => setShowMenu(false)}
        style={{ '--z-index': 1001 }}
      >
        <ul className="menu-list">
          {mobileSubMenus.map((item, index) => (
            <li className="menu-item" key={index} onClick={() => clickMenu(item.name)}>
              {item.text}
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
            </li>
          ))}
        </ul>
      </Mask>
    </div>
  )
}

export default Introduce
