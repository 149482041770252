/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 14:04:35
 */
import React from 'react'
import { withRouter } from 'react-router-dom'

function Digital({ history, showAnimation, info }) {
  return (
    <div className="mobile-block digital" id="digital" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-block-title.png" />
      <div className="digital-list">
        {info.moduleContent && info.moduleContent.map((item, index) => (
          <div className={`digital-item item-${index + 1} ${showAnimation ? 'appear' : ''}`} key={index}>
            <img className="image" src={item.pic} />
            <div className="title-super">
              <img className="icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-icon-${index + 1}.png`} />
              <img className="step" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-step-${index + 1}.png`} />
              <div className="title">{item.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="digital-button" onClick={() => history.push('/mobile/videocenter')}>查看作品</div>
      <img className="block-devide-2" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-2.png" />
    </div>
  )
}

export default withRouter(Digital)
