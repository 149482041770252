/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 13:39:17
 */
import React from 'react'

function Asset({ showAnimation, info }) {
  return (
    <div className="mobile-block assets" id="assets" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-title.png" />
      <div className="block-subtitle" dangerouslySetInnerHTML={{ __html : info.moduleSubtitle }}></div>
      <img className={`assets-box-1 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-box-1.png" />
      <img className={`assets-img-1 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-img-1.png" />
      <img className="assets-car" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-car.png" />
      <img className="assets-woman" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-woman.png" />
      <img className={`assets-box-2 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-box-2.png" />
      <img className={`assets-box-3 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-box-3.png" />
      <img className={`assets-img-2 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-img-2.png" />
      <img className={`assets-img-3 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-block-img-3.png" />
      <img className="block-devide-1" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-1.png" />
    </div>
  )
}

export default Asset
