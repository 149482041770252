/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-08 10:54:25
 */
import React, { useState, useEffect } from 'react'
import { regExt } from '/libs/constant'
import { Button, Mask, Form, TextArea, Input } from 'antd-mobile'
import api from '/api'

function Collection({ visible, onClose }) {
  const [showForm, setShowForm] = useState(true)
  const [advList, setAavList] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    api.getAdvList().then(res => {
      setAavList([{id: undefined, value: '请选择'}].concat(res.advList))
    })
  }, [])

  const handleClose = () => {
    onClose()
    setShowForm(true)
    form.resetFields()
  }

  const onFinish = values => {
    api.getToken().then(res => {
      values._csrf = res.token
      api.saveContact(values).then(() => {
        setShowForm(false)
      }).catch(error => {
        console.log(error)
      })
    })
  }

  return (
    <Mask
      className="collection-modal-super"
      visible={visible}
      style={{ '--z-index': 1000 }}
    >
      <div className="modal-content">
        <img 
          className="close-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/close-icon.png" 
          onClick={handleClose}
        />
        {showForm ? (
          <div className="form-content">
            <div className="title">免费获取报价及解决方案</div>
            <div className="subtitle">我们将尽快联系您，真诚为您服务！</div>
            <Form
              form={form}
              onFinish={onFinish}
              footer={
                <Button type='submit' color='primary'>
                  立即提交
                </Button>
              }
              layout='horizontal'
              className="form"
            >
              <div className="form-row">
                <Form.Item 
                  name='name'
                  label="联系人"
                  rules={[{ required: true, message: '姓名不能为空' }]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item 
                  name='mobile'
                  label="联系电话"
                  rules={[
                    { required: true, message: '联系方式不能为空' },
                    { pattern: regExt.phone, message: '手机号格式不正确' },
                  ]}
                >
                  <Input placeholder="请输入联系电话" type='number' />
                </Form.Item>
              </div>
              <div className="form-row">
                <Form.Item 
                  name='companyName'
                  label="公司名称"
                  rules={[{ required: true, message: '公司名称不能为空' }]}
                >
                  <Input placeholder="请输入公司名称" />
                </Form.Item>
                <Form.Item 
                  name='adv'
                  label="所属行业"
                >
                  <div className="adm-input">
                    <select className="adm-input-element form-select">
                      {advList.map((item, index) => <option value={item.id} key={index}>{item.value}</option>)}
                    </select>
                  </div>
                </Form.Item>
              </div>
              <Form.Item 
                name='desc'
                label="应用场景"
              >
                <TextArea
                  placeholder='请简单描述您期望应用的场景'
                  rows={3}
                />
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div className="complete-content">
            <img className="complete-circle" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/complete-circle.png" />
            <img className="complete-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/complete-icon.png" />
            <div className="complete-title">提交成功</div>
            <div className="complete-subtitle">我们将尽快联系您，真诚为您服务！</div>
            <div className="button-super">
              <div className="button-close" onClick={handleClose}>关闭</div>
            </div>
          </div>
        )}
      </div>
    </Mask>
  )
}

export default Collection
