/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 14:13:37
 */
import React, { useState, useEffect, useRef } from 'react'
import { terminals } from '/libs/constant'

function Terminal({ info }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const terminalRef = useRef(null)
  //定义变量，用于记录坐标和角度
  const [taskswiper] = useState({
    startX: 0,
    startY: 0,
    moveX: 0,
    moveY: 0,
  })

  useEffect(() => {
    terminalRef.current.addEventListener('touchmove', handleTouchMove, { passive: false })
  }, [])

  const handleTouchs = (evt, prev, next) => {
    if(evt.type=="touchstart"){//通过if语句判断event.type执行了哪个触摸事件
      if (evt.targetTouches.length == 1) {
        taskswiper.startX = evt.targetTouches[0].pageX;
        taskswiper.startY = evt.targetTouches[0].pageY;
     }
    } else if (evt.type == "touchend" || evt.type == "touchcancel"){//当手指离开屏幕或系统取消触摸事件的时候
      taskswiper.moveX = evt.changedTouches[0].pageX - taskswiper.startX;
      taskswiper.moveY = evt.changedTouches[0].pageY - taskswiper.startY;
      // 判断是否为横向移动及是否达到滚动距离
      if (Math.abs(taskswiper.moveX) <= Math.abs(taskswiper.moveY) || Math.abs(taskswiper.moveX) < 30) return false
      // 判断滑动方向
      if (taskswiper.moveX > 0) {
        next()
      } else {
        prev()
      }
      return false
    }
  }

  const handleTouchMove = evt => {
    taskswiper.moveX = evt.targetTouches[0].pageX - taskswiper.startX;
    taskswiper.moveY = evt.targetTouches[0].pageY - taskswiper.startY;
    //横向移动大于纵向移动
    if (Math.abs(taskswiper.moveX) > Math.abs(taskswiper.moveY) && evt.cancelable) {
        //阻止纵向滚动事件
        evt.preventDefault();
    }
  }

  const swipeLeft = () => {
    const active = activeIndex - 1
    setActiveIndex(active >= 0 ? active : 2 )
  }

  const swipeRight = () => {
    const active = activeIndex + 1
    setActiveIndex(active <= 2 ? active : 0 )
  }

  return (
    <div className="mobile-block terminal" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/terminal-block-title.png" />
      <div 
        className="terminal-slide-super" 
        onTouchStart={evt => handleTouchs(evt, swipeLeft, swipeRight)}
        onTouchEnd={evt => handleTouchs(evt, swipeLeft, swipeRight)}
        ref={terminalRef}
      >
        {terminals.map((item, index) => {
          const active = index + activeIndex
          return (
            <div 
              className={`terminal-super terminal-${item.name} position-${active < 3 ? active : active - 3 }`} 
              key={index}
              style={{ backgroundImage: `url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/terminal-s-${item.name}.png)`}}
            >
              <div className="title">{item.title}</div>
              <div className="intro">{item.intro}</div>
            </div>
          )
        })}
      </div>
      <div className="button-group">
        <div className="button-box" onClick={swipeLeft}>
          <img className="button-arrow prev" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/swiper-arrow.png" />
        </div>
        <div className="button-box" onClick={swipeRight}>
          <img className="button-arrow next" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/swiper-arrow.png" />
        </div>
      </div>
      <div className="dots-group">
        {terminals.map((item, index) => (
          <div className={`dot ${activeIndex === index ? 'active' : '' }`} key={index}></div>
        ))}
      </div>
      <img className="block-devide-2" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-2.png" />
    </div>
  )
}

export default Terminal
