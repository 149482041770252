/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-17 09:12:18
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Collection from './Collection'
import Introduce from './Introduce'
import VideoCenter from './VideoCenter'
import Home from './Home'

class Mobile extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/home'} component={Home}/>
          <Route exact path={path + '/collection'} component={Collection}/>
          <Route exact path={path + '/introduce'} component={Introduce}/>
          <Route exact path={path + '/videocenter'} component={VideoCenter}/>
          <Route component={Home} />
        </Switch>
      </div>
    )
  }
  
}

export default withRouter(Mobile)
