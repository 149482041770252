/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 14:39:02
 */
import React, { useState, useEffect, useRef } from 'react'
import { partners } from '/libs/constant'

function Product({ info }) {
  const [activeTab, setActiveTab] = useState(0)
  const productRef = useRef(null)
  //定义变量，用于记录坐标和角度
  const [taskswiper] = useState({
    startX: 0,
    startY: 0,
    moveX: 0,
    moveY: 0,
  })

  useEffect(() => {
    // touchmove中可使用evt.preventDefault()
    productRef.current.addEventListener('touchmove', handleTouchMove, { passive: false })
  }, [])

  const handleTouchs = (evt, prev, next) => {
    if(evt.type=="touchstart"){//通过if语句判断event.type执行了哪个触摸事件
      if (evt.targetTouches.length == 1) {
        taskswiper.startX = evt.targetTouches[0].pageX;
        taskswiper.startY = evt.targetTouches[0].pageY;
     }
    } else if (evt.type == "touchend" || evt.type == "touchcancel"){//当手指离开屏幕或系统取消触摸事件的时候
      taskswiper.moveX = evt.changedTouches[0].pageX - taskswiper.startX;
      taskswiper.moveY = evt.changedTouches[0].pageY - taskswiper.startY;
      // 判断是否为横向移动及是否达到滚动距离
      if (Math.abs(taskswiper.moveX) <= Math.abs(taskswiper.moveY) || Math.abs(taskswiper.moveX) < 60) return false
      // 判断滑动方向
      if (taskswiper.moveX > 0) {
        next()
      } else {
        prev()
      }
      return false
    }
  }

  // 横向滑动时阻止页面纵向滚动
  const handleTouchMove = evt => {
    taskswiper.moveX = evt.targetTouches[0].pageX - taskswiper.startX;
    taskswiper.moveY = evt.targetTouches[0].pageY - taskswiper.startY;
    //横向移动大于纵向移动
    if (Math.abs(taskswiper.moveX) > Math.abs(taskswiper.moveY) && evt.cancelable) {
      //阻止纵向滚动事件
      evt.preventDefault();
    }
  }

  const swipeLeft = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1)
    }
  }

  const swipeRight = () => {
    if (activeTab < info.moduleContent.length - 1) {
      setActiveTab(activeTab + 1)
    }
  }

  return (
    <div className="mobile-block product" id="product" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/product-block-title-1.png" />
      <div 
        onTouchStart={evt => handleTouchs(evt, swipeLeft, swipeRight)}
        onTouchEnd={evt => handleTouchs(evt, swipeLeft, swipeRight)}
        ref={productRef}
        style={{ touchAction: 'pan-y' }}
      >
        <div className="product-tab-title">
          {info.moduleContent && info.moduleContent.map((item, index) => (
            <div 
              className={`title-item ${activeTab === index ? 'active' : ''}`}
              key={index}
              onClick={() => setActiveTab(index)}
            >{item.title}</div>
          ))}
        </div>
        <div className="product-tab-content">
          {info.moduleContent && info.moduleContent.map((item, index) => (
            <div 
              className={`content-item ${activeTab === index ? 'active' : ''}`}
              key={index}
            >
              <img className="item-img" src={item.pic} />
              <div className="item-intro">{item.subtitle}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile-partner" id="partner">
        {partners.map((name, index) => (
          <div className="partner-item" key={index}>
            <img className="item-img" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-partner-logo-${index + 1}.png`} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Product
