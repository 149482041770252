/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-22 11:52:56
 */
import React, { useState, useEffect } from 'react'
import { regExt, mobileSubMenus, navMenus } from '/libs/constant'
import { Button, Mask, Toast, Form, TextArea, Input, Picker } from 'antd-mobile'
import api from '/api'
import './index.less'

function Collection({ history }) {
  const [showMenu, setShowMenu] = useState(false)
  const [advList, setAavList] = useState([])
  const [pickerVisible, setPickerVisible] = useState(false)
  const [menus, setMenus] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    scrollToAnchor('collection')
    api.getAdvList().then(res => {
      const data = []
      res.advList.forEach(item => {
        data.push({
          label: item.value,
          value: item.id
        })
      })
      setAavList([data])
    })
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '2')
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.mobileId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
    })
  }, [])

  const clickMenu = name => {
    switch (name) {
      case 'home': 
        history.push('/mobile', { id: 'home'})
        break
      default:
        history.push('/mobile/introduce', { id: name})
    }
  }

  const clickNav = id => history.push('/mobile', { id })

  // 滚动到顶部
  const scrollToTop = () => {
    let scrollTimer = setInterval(() => {
      const  scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const ispeed = Math.ceil(scrollTop / 6)
      if(scrollTop <= 0){
        clearInterval(scrollTimer)
        scrollTimer = null
      } else {
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop - ispeed
      }
    },30)
  }

  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }

  const onFinish = values => {
    if (values.adv) {
      values.adv = values.adv[0]
    }
    api.getToken().then(res => {
      values._csrf = res.token
      api.saveContact(values).then(data => {
        Toast.show({
          icon: 'success',
          content: '提交成功',
          afterClose: () => {
            history.goBack()
          },
        })
      }).catch(error => {
        console.log(error)
      })
    })
  }

  return (
    <div className="mobile-container">
      <div className="nav-super">
        <img 
          className="nav-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => history.push('/mobile', { id: 'home'})}
        />
        <img 
          className={`nav-button ${showMenu ? 'close' : ''}`}
          src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/${showMenu ? 'mobile-close-img' : 'nav-button'}.png`}
          onClick={toggleMenu}
        />
      </div>
      <div className="mobile-section" id="collection">
        <div className="form-super">
          <div className="title-super">
            <div className="title">免费获取报价及解决方案</div>
            <div className="subtitle">我们将尽快联系您，真诚为您服务！</div>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            footer={
              <Button block type='submit' color='primary'>
                立即提交
              </Button>
            }
            className="form-content"
          >
            <Form.Item 
              name='name'
              label="姓名"
              rules={[{ required: true, message: '姓名不能为空' }]}
            >
              <Input placeholder="请输入姓名" />
            </Form.Item>
            <Form.Item 
              name='mobile'
              label="联系电话"
              rules={[
                { required: true, message: '联系方式不能为空' },
                { pattern: regExt.phone, message: '手机号格式不正确' },
              ]}
            >
              <Input placeholder="请输入联系电话" type='number' />
            </Form.Item>
            <Form.Item 
              name='companyName'
              label="公司名称"
              rules={[{ required: true, message: '公司名称不能为空' }]}
            >
              <Input placeholder="请输入公司名称" />
            </Form.Item>
            <Form.Item 
              name='adv'
              label="所属行业"
              trigger='onConfirm'
              onClick={() => {
                setPickerVisible(true)
              }}
            >
              <Picker
                columns={advList}
                visible={pickerVisible}
                onClose={() => {
                  setPickerVisible(false)
                }}
              >
                {
                  value => value.length > 0 ? 
                    <span className="picker-text">{value[0].label}</span> : 
                    <span className="picker-text placeholder">请选择所属行业</span>
                }
              </Picker>
            </Form.Item>
            <Form.Item 
              name='desc'
              label="应用场景"
            >
              <TextArea
                placeholder='请简单描述您期望应用的场景'
                rows={3}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="mobile-bottom-nav">
        <ul className="nav-list">
          <li className="nav-item" onClick={() => clickNav('assets')}>
            <div>{menus[1] && menus[1].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('infinite')}>
            <div>{menus[3] && menus[3].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('nft')}>
            <div>{menus[4] && menus[4].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'introduce' })}>
            <div>关于我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'contact' })}>
            <div>联系我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
        </ul>
      </div>
      <div className="mobile-footer">
        <div className="license-list">
          <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
          <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
            <img 
              className="police-logo" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
            />
            京公网安备 11011202003539号
          </div>
        </div>
        <img 
          className="totop-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-totop-icon.png" 
          onClick={scrollToTop}
        />
      </div>
      <Mask 
        className="menu-modal-super"
        visible={showMenu} 
        onMaskClick={() => setShowMenu(false)}
        style={{ '--z-index': 1001 }}
      >
        <ul className="menu-list">
          {mobileSubMenus.map((item, index) => (
            <li className="menu-item" key={index} onClick={() => clickMenu(item.name)}>
              {item.text}
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
            </li>
          ))}
        </ul>
      </Mask>
    </div>
  )
}

export default Collection
