/*
* @Description: 
* @Author: 杨志航<yangzhihang@372163.com>
* @Date: 2021-11-23 17:30:04
*/
/**
 * 
 * 
 * 方法接受两个参数 
 *  查询对象
 *  是否开启loading
 */

import urls from './urls'
import { Toast } from 'antd-mobile'

const API = {}
let fetchTimer = null

for (let k in urls) {//eslint-disable-line
  API[k] = (query, isFile) => {
    // 有查询参数
    if (typeof query === 'object') {
      return fetcher(urls[k], query, isFile)
    }
    // 无查询参数 有loading
    if (typeof query === 'boolean') {
      return fetcher(urls[k], null, isFile)
    }
    // 无查询参数 无loading
    if (typeof query === 'undefined') {
      return fetcher(urls[k])
    }
    Toast.show({
    icon: 'fail',
    content: '参数错误：查询参数必须是objec类型',
  })
    console.error('参数错误：查询参数必须是objec类型')
  }
}

const fetcher = (urlObj, params, isFile) => {
  const headers = new Headers()
  const config = {
    method: urlObj.method || 'GET',
    credentials: 'include',
    headers: headers
  }

  let url = urlObj.url

  const customHeaders = urlObj.headers || {}
  for(let hName in customHeaders){
    headers.append(hName, customHeaders[hName])
  }


  if (urlObj.method.toUpperCase() === 'POST') {
    if (isFile) {
    const fd = new FormData()
    fd.append('file', params.file)
    config.body = fd
    } else {
    headers.append('Content-Type', 'application/json')
    config.body = JSON.stringify(params)
    }
    
  }
  else if (params) {
    url += url.indexOf('?') === -1 ? '?' : '&'
    for (let k in params) {//eslint-disable-line
      const val = params[k] === undefined ? '' : params[k]
      url += `${encodeURIComponent(k)}=${encodeURIComponent(val)}&`
    }
    url = url.slice(0, -1)
  }
  // 添加token
  const token = localStorage.getItem('token') 
  if (token) {
    headers.append('token', token)
  }

  Toast.show({
  icon: 'loading',
  content: '加载中…',
  duration: 0,
})
  return fetch(url, config)
    .then(response => response.json())
    .then(result => {
      // console.log('result=>', result)
      Toast.clear()
      if (+result.code === 0) {
        return result.data || result.message
      } else {
        Toast.show({
          icon: 'fail',
          content: result.message || '服务器发生错误，请联系管理员',
        })
      }
    //  if (+result.code === 372163) {
    //    setTimeout(() => window.location = '/', 1500)
    //  }
    //  if (+result.code === 1002) {
    //    setTimeout(() => window.location = '/index', 1500)
    //  }
    //  if (+result.code === 1001) {
    //    localStorage.removeItem('token')
    //    setTimeout(() => window.location = '/', 1500)
    //  }
      return Promise.reject(result)
    })
    .catch(err => {
    // Toast.clear()
      if(err.code === 1001 || err.code === 1002 || !err.code){//1001表示登录失效1002表示没有访问权限 防止弹多次窗////500的时候接口异常也在这里处理,500的时候err中没有code
        if(!fetchTimer) {
          Toast.show({
            icon: 'fail',
            content: err.message || '服务器发生错误，请联系管理员',
          })
        }
        clearTimeout(fetchTimer)
        fetchTimer = setTimeout(() => {
          fetchTimer = null
        }, 1000)
      }else{
      Toast.show({
        icon: 'fail',
        content: err.message || '服务器发生错误，请联系管理员',
      })
      }
      return Promise.reject(err || { message: '服务器发生错误，请联系管理员' })
    })
}

export default API