/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 15:48:44
 */
import React, { useState, useEffect, useRef } from 'react'
import { mobileSubMenus, navMenus } from '/libs/constant'
import { formatDigitalData } from '/libs/util'
import { Mask } from 'antd-mobile'
import api from '/api'
import './index.less'

function VideoCenter({ history }) {
  const [showMenu, setShowMenu] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [showVideo, setShowVideo] = useState(false)
  const [digitalVideos, setDigitalVideos] = useState([])
  const [videoUrl, setVideoUrl] = useState('')
  const [menus, setMenus] = useState([])
  const videoRef = useRef(null)
  const tabsRef = useRef(null)
  //定义变量，用于记录坐标和角度
  const [taskswiper] = useState({
    startX: 0,
    startY: 0,
    moveX: 0,
    moveY: 0,
  })

  useEffect(() => {
    // touchmove中可使用evt.preventDefault()
    tabsRef.current.addEventListener('touchmove', handleTouchMove, { passive: false })
    scrollToAnchor('videoCenter')
    api.getVideoList().then(res => {
      const videos = res.filter(item => item.channel !== '1')
      const digitalData = formatDigitalData(videos.filter(item => item.module === '3'))
      setDigitalVideos(digitalData)
    })
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '2')
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.mobileId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
    })
  }, [])

  const handleTouchs = (evt, prev, next) => {
    if(evt.type=="touchstart"){//通过if语句判断event.type执行了哪个触摸事件
      if (evt.targetTouches.length == 1) {
        taskswiper.startX = evt.targetTouches[0].pageX;
        taskswiper.startY = evt.targetTouches[0].pageY;
     }
    } else if (evt.type == "touchend" || evt.type == "touchcancel"){//当手指离开屏幕或系统取消触摸事件的时候
      taskswiper.moveX = evt.changedTouches[0].pageX - taskswiper.startX;
      taskswiper.moveY = evt.changedTouches[0].pageY - taskswiper.startY;
      // 判断是否为横向移动及是否达到滚动距离
      if (Math.abs(taskswiper.moveX) <= Math.abs(taskswiper.moveY) || Math.abs(taskswiper.moveX) < 60) return false
      // 判断滑动方向
      if (taskswiper.moveX > 0) {
        next()
      } else {
        prev()
      }
      return false
    }
  }

  // 横向滑动时阻止页面纵向滚动
  const handleTouchMove = evt => {
    taskswiper.moveX = evt.targetTouches[0].pageX - taskswiper.startX;
    taskswiper.moveY = evt.targetTouches[0].pageY - taskswiper.startY;
    //横向移动大于纵向移动
    if (Math.abs(taskswiper.moveX) > Math.abs(taskswiper.moveY) && evt.cancelable) {
      //阻止纵向滚动事件
      evt.preventDefault();
    }
  }

  const swipeLeft = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1)
    }
  }

  const swipeRight = () => {
    if (activeTab < digitalVideos.length - 1) {
      setActiveTab(activeTab + 1)
    }
  }

  const clickMenu = name => {
    switch (name) {
      case 'home': 
        history.push('/mobile', { id: 'home'})
        break
      default:
        history.push('/mobile/introduce', { id: name})
    }
  }

  const clickNav = id => history.push('/mobile', { id })

  // 滚动到顶部
  const scrollToTop = () => {
    let scrollTimer = setInterval(() => {
      const  scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const ispeed = Math.ceil(scrollTop / 6)
      if(scrollTop <= 0){
        clearInterval(scrollTimer)
        scrollTimer = null
      } else {
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop - ispeed
      }
    },30)
  }

  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }

  const handlePlay = url => {
    setVideoUrl(url)
    setShowVideo(true)
    videoRef.current.load()
  }

  const handleClose = () => {
    setShowVideo(false)
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }

  return (
    <div className="mobile-container">
      <div className="nav-super">
        <img 
          className="nav-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => history.push('/mobile', { id: 'home'})}
        />
        <img 
          className={`nav-button ${showMenu ? 'close' : ''}`}
          src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/${showMenu ? 'mobile-close-img' : 'nav-button'}.png`}
          onClick={toggleMenu}
        />
      </div>
      <div className="bottom-super">
        <div>定制您的专属方案！</div>
        <div className="contact-button" onClick={() => history.push('/mobile/collection')}>联系我们</div>
      </div>
      <div className="video-center-block" id="videoCenter">
        <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/video-center-title.png" />
        <div 
          className="tabs-super"
          onTouchStart={evt => handleTouchs(evt, swipeLeft, swipeRight)}
          onTouchEnd={evt => handleTouchs(evt, swipeLeft, swipeRight)}
          ref={tabsRef}
          style={{ touchAction: 'pan-y' }}
        >
          <div className="tab-title">
            {digitalVideos.map((item, index) => (
              <div 
                className={`title-item ${activeTab === index ? 'active' : ''}`}
                key={index}
                onClick={() => setActiveTab(index)}
              >{item.title}</div>
            ))}
          </div>
          <div className="video-list">
            {digitalVideos[activeTab] && digitalVideos[activeTab].videos.map((item, index) => (
              <div className="video-item" key={index}>
                <div className="poster-super" onClick={() => handlePlay(item.videoAddress)}>
                  <img className="image" src={item.mainPic} />
                  <img className="play-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-play-icon.png" />
                </div>
                <div className="text-super">
                  <div className="title">{item.videoTopic}</div>
                  <div className="subtitle">{item.videoSubtitle}</div>
                  <div className="date">{item.makeTime.split(' ')[0]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mobile-bottom-nav">
        <ul className="nav-list">
          <li className="nav-item" onClick={() => clickNav('assets')}>
            <div>{menus[1] && menus[1].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('infinite')}>
            <div>{menus[3] && menus[3].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => clickNav('nft')}>
            <div>{menus[4] && menus[4].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'introduce' })}>
            <div>关于我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'contact' })}>
            <div>联系我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
        </ul>
      </div>
      <div className="mobile-footer">
        <div className="license-list">
          <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
          <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
            <img 
              className="police-logo" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
            />
            京公网安备 11011202003539号
          </div>
        </div>
        <img 
          className="totop-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-totop-icon.png" 
          onClick={scrollToTop}
        />
      </div>
      <div className="bottom-blank"></div>
      <Mask 
        className="menu-modal-super"
        visible={showMenu} 
        onMaskClick={() => setShowMenu(false)}
        style={{ '--z-index': 1001 }}
      >
        <ul className="menu-list">
          {mobileSubMenus.map((item, index) => (
            <li className="menu-item" key={index} onClick={() => clickMenu(item.name)}>
              {item.text}
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
            </li>
          ))}
        </ul>
      </Mask>
      <Mask 
        className="video-modal-super"
        visible={showVideo} 
        onMaskClick={handleClose}
        style={{ '--z-index': 1003 }}
        forceRender
      >
        <video 
          className="video-player"
          src={videoUrl}
          loop
          autoPlay={showVideo}
          ref={videoRef}
          controls
        >
          您的浏览器不支持 video 标签。
        </video>
      </Mask>
    </div>
  )
}

export default VideoCenter
