/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 14:03:10
 */
import React, { useState } from 'react'

function Infinite({ info }) {
  const [activeBox, setActiveBox] =useState(-1)

  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-box-title.png" />
        <div className="infinite-subtitle" dangerouslySetInnerHTML={{ __html : info.moduleSubtitle }}></div>
        <div className="accordion">
          {info.moduleContent && info.moduleContent.map((item, index) => (
            <div 
              className={`unchecked ${activeBox === index ? 'on' : ''}`}
              style={{ 
                backgroundImage: `url(${item.pic})`,
                zIndex: activeBox === index ? 4 : 0
                }}
              key={index}
              onMouseEnter={() => setActiveBox(index)}
              onMouseLeave={() => setActiveBox(-1)}
            >
              <div className="zg1" style={{ display: activeBox === index || activeBox=== -1 ? 'none' : 'block' }}></div>
              {
                (index === 0 || index === 3) && <div className="zg2" style={{ display: activeBox === index || activeBox=== -1 ? 'none' : 'block' }}></div>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Infinite
