/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-02 13:36:10
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Home from './Home'
import Message from './Message'
import VideoCenter from './VideoCenter'

class Computer extends Component {
  render () {
    const { path } = this.props.match

    return (
      <div>
        <Switch>
          <Route exact path={path + '/home'} component={Home}/>
          <Route exact path={path + '/message/:slug'} component={Message}/>
          <Route exact path={path + '/videocenter'} component={VideoCenter}/>
          <Route component={Home} />
        </Switch>
      </div>
    )
  }
  
}

export default withRouter(Computer)
