/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 14:12:02
 */
import React, { useState, useEffect } from 'react'

function Product({ info }) {
  const artics = ['VIRTUAL LIVE BROADCAST', 'DIGITAL CONTENT', 'INFRASTRUCTURE']
  const [datas, setDatas] = useState([])

  useEffect(() => {
    if (info.moduleContent) {
      setDatas(info.moduleContent.map((item, index) => {
        item.en = artics[index]
        return item
      }))
    }
  }, [info])

  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-product-title-1.png" />
        <ul className="product-list">
          {datas.map((item, index) => (
            <li className="product-item" key={index}>
              <div className="product-box">
                {/* <div className="item-icon">NEWS</div> */}
                <img className="item-img" src={item.pic} />
                <div className="item-content">
                  <div className="item-title">{item.title}</div>
                  <div className="item-en">{item.en}</div>
                  <div className="item-intro">{item.subtitle}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Product
