/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-11 09:46:22
 */
/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 14:12:02
 */
import React from 'react'
import { withRouter } from 'react-router-dom'

function Digital({ history, info }) {
  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-digital-title.png" />
        <div className="digital-link-super">
          <div className="title" dangerouslySetInnerHTML={{ __html : info.moduleSubtitle }}></div>
          <div className="digital-link-button" onClick={() => history.push('/computer/videocenter')}>查看作品</div>
        </div>
        <div className="digital-person-list">
          {info.moduleContent && info.moduleContent.map((item, index) => (
            <div className="digital-person-item" key={index}>
              <img className="item-person" src={item.pic} />
              <img className="item-icon" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-icon-${index + 1}.png`} />
              <img className="item-step" src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-step-${index + 1}.png`} />
              <div className="item-en">{item.subtitle}</div>
              <div className="item-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Digital)
