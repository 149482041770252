/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 11:36:42
 */
import React from 'react'
import { withRouter } from 'react-router-dom'

function Partner({ history, fullpageApi, info, menus }) {
  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-partner-title.png" />
        <img className="partner-logos" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/partner-logos.png" />
      </div>
      {/* <img 
        className="back-arrow" 
        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/back-arrow.png" 
        onClick={() => fullpageApi.moveTo(1)}
      /> */}
      <div className="footer-super fullpage">
        <div className="logo-content">
          <img 
            className="logo-td" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/td-logo.png" 
            onClick={() => window.open('http://www.372163.com/')}
          />
          <div className="logo-devide"></div>
          <img 
            className="logo-qy" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qy-logo.png" 
            onClick={() => fullpageApi.moveTo(1)}
          />
        </div>
        <div className="message-content">
          <div className="menu-list">
            <div className="menu-item" onClick={() => fullpageApi.moveTo(2)}>{menus[1] && menus[1].text}</div>
            <div className="menu-item" onClick={() => fullpageApi.moveTo(4)}>{menus[3] && menus[3].text}</div>
            <div className="menu-item" onClick={() => fullpageApi.moveTo(6)}>{menus[4] && menus[4].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer/message/introduce')}>关于我们</div>
            <div className="menu-item" onClick={() => history.push('/computer/message/contact')}>联系我们</div>
          </div>
          <div className="license-list">
            <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
            <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
              <img 
                className="police-logo" 
                src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
              />
              京公网安备 11011202003539号
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Partner)
