/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-12 14:23:52
 */
import React, { useState, useRef, useEffect } from 'react'
import { navMenus } from '/libs/constant'
import { Toast, Mask } from 'antd-mobile'
import { CalendarOutline } from 'antd-mobile-icons'
import { formatDigitalData } from '/libs/util'
import api from '/api'
import './index.less'

function VideoCenter({ history }) {
  const [showVideo, setShowVideo] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [digitalVideos, setDigitalVideos] = useState([])
  const [videoUrl, setVideoUrl] = useState('')
  const [menus, setMenus] = useState([])
  const videoRef = useRef()

  useEffect(() => {
    api.getVideoList().then(res => {
      const videos = res.filter(item => item.channel !== '2')
      const digitalData = formatDigitalData(videos.filter(item => item.module === '3'))
      setDigitalVideos(digitalData)
    })
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '1')
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.computerId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
    })
  }, [])

  const clickMenu = index => {
    switch (index) {
      case 0:
        history.push('/computer#1')
        break
      case 1:
        history.push('/computer#2')
        break
      case 2:
        history.push('/computer#3')
        break
      case 3:
        history.push('/computer#4')
        break
      case 4:
        history.push('/computer#5')
        break
      case 5:
        history.push('/computer#7')
        break
      case 6:
        history.push('/computer#8')
        break
    }
  }

  const handlePlay = url => {
    setVideoUrl(url)
    setShowVideo(true)
    videoRef.current.load()
  }

  const handleClose = () => {
    setShowVideo(false)
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }
  
  return (
    <div className="pc-container">
      <div className="header-super">
        <img 
          className="header-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => history.push('/computer#1')}
        />
        <div className="header-content">
          <ul className="menu-list">
            {menus.map((item, index) => (
              <li 
                className="menu-item"
                key={index}
                onClick={() => clickMenu(index)}
              >{item.text}</li>
            ))}
          </ul>
          <div className="explore" onClick={() => {
            Toast.show({
              icon: <CalendarOutline />,
              content: '即将开启~',
            })
          }}>探索街区</div>
        </div>
      </div>
      <div className="header-mask"></div>
      <div className="page-block">
        <div className="tabs-super">
          <ul className="tab-menu-list video-center">
            {digitalVideos.map((item, index) => (
              <li 
                className={`tab-menu-item ${activeTab === index ? 'active' : ''}`} 
                key={index}
                onClick={() => setActiveTab(index)}
              >
                {item.title}
              </li>
            ))}
          </ul>
          <div className="video-content">
            <div className="title-super">
              <div className="en">VIDEO CENTER</div>
              <div className="name">视频中心</div>
            </div>
            <div className="video-list">
              {digitalVideos[activeTab] && digitalVideos[activeTab].videos.map((item, index) => (
                <div className="video-item" key={index}>
                  <div className="poster-super" onClick={() => handlePlay(item.videoAddress)}>
                    <img className="image" src={item.mainPic} />
                    <img className="play-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-play-icon.png" />
                  </div>
                  <div className="text-super">
                    <div className="title">{item.videoTopic}</div>
                    <div className="subtitle" title={item.videoSubtitle}>{item.videoSubtitle}</div>
                    <div className="date">{item.makeTime.split(' ')[0]}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-super">
        <div className="logo-content">
          <img 
            className="logo-td" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/td-logo.png" 
            onClick={() => window.open('http://www.372163.com/')}
          />
          <div className="logo-devide"></div>
          <img 
            className="logo-qy" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qy-logo.png" 
            onClick={() => history.push('/computer#1')}
          />
        </div>
        <div className="message-content">
          <div className="menu-list">
            <div className="menu-item" onClick={() => history.push('/computer#2')}>{menus[1] && menus[1].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer#4')}>{menus[3] && menus[3].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer#6')}>{menus[4] && menus[4].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer/message/introduce')}>关于我们</div>
            <div className="menu-item" onClick={() => history.push('/computer/message/contact')}>联系我们</div>
          </div>
          <div className="license-list">
            <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
            <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
              <img 
                className="police-logo" 
                src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
              />
              京公网安备 11011202003539号
            </div>
          </div>
        </div>
      </div>
      <Mask
        className="video-modal-super"
        visible={showVideo}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        <div className="modal-content">
          <img 
            className="close-icon" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/close-icon.png" 
            onClick={handleClose}
          />
          <video
            className="video-player"
            src={videoUrl}
            loop
            autoPlay={showVideo}
            controls
            ref={videoRef}
          >您的浏览器不支持 video 标签。</video>
        </div>
      </Mask>
    </div>
  )
}

export default VideoCenter
