/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-07-27 11:02:34
 */
import React from 'react'
import './index.less'

function Nft() {
  return (
    <div className="section-block pc-nft-container">
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-nft-title.png" />
      </div>
      <div className="nft-content-super">
        <div className="nft-info-super">
          <div className="nft-title">一站式【数字藏品】交易平台</div>
          <div className="nft-desc">无限数藏是启元天地旗下数字资产综合服务平台，区块链底层基础设施支撑由数文链提供，为企业提供数字藏品存证、备案、登记、发行、销售等服务。拥有从创意制作到发行销售的完整生态链路及成熟的数字藏品发行经验，可发行作品类型包括但不限于图片、音乐、视频、3D模型等，面向文娱、时尚、地产、体育、消费等多个领域，致力于元宇宙营销和数字经济增长。</div>
          <div className="nft-qr-list">
            <div className="nft-qr-item">
              <img className="qr-item-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-nft-download.png" />
              <div className="qr-item-text">扫码下载APP</div>
            </div>
            <div className="nft-qr-item">
              <img className="qr-item-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-nft-wechat.png" />
              <div className="qr-item-text">扫码关注公众号</div>
            </div>
          </div>
        </div>
        <img className="pc-nft-main-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/nft-main-img.gif" />
      </div>
    </div>
  )
}

export default Nft
