/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-02 13:39:43
 */
import React, { useState, useRef, useEffect } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { navMenus, storageKey } from '/libs/constant'
import { Toast } from 'antd-mobile'
import { CalendarOutline } from 'antd-mobile-icons'
import Videos from './components/Videos'
import Asset from './components/Asset'
import Digital from './components/Digital'
import Infinite from './components/Infinite'
import Terminal from './components/Terminal'
import Product from './components/Product'
import Partner from './components/Partner'
import Collection from './components/Collection'
import Nft from './components/Nft'
import api from '/api'
import './index.less'

function Home() {
  const [activeMenu, setActiveMenu] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingVideo, setLoaddingVideo] = useState({})
  const [bannerVideos, setBannerVideos] = useState([])
  const [assetInfo, setAssetInfo] = useState({})
  const [terminalInfo, setTerminalInfo] = useState({})
  const [partnerInfo, setPartnerInfo] = useState({})
  const [digitalInfo, setDigitalInfo] = useState({})
  const [infiniteInfo, setInfiniteInfo] = useState({})
  const [productInfo, setProductInfo] = useState({})
  const [menus, setMenus] = useState([])
  const fullpageRef = useRef()

  useEffect(() => {
    const key = sessionStorage.getItem(storageKey)
    if (key) {
      setLoading(false)
    } else {
      sessionStorage.setItem(storageKey, 'loaded')
      fullpageRef.current.fullpageApi.setAllowScrolling(false)
    }
    api.getVideoList().then(res => {
      const videos = res.filter(item => item.channel !== '2')
      const loadingData = videos.find(item => item.module === '1')
      const bannerData = videos.filter(item => item.module === '2').sort((a, b) => b.weight - a.weight)
      setLoaddingVideo(loadingData)
      setBannerVideos(bannerData)
    })
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '1')
      infos.forEach(item => {
        if (item.moduleContent !== '') {
          try {
            item.moduleContent = JSON.parse(item.moduleContent)
          } catch (err) {
            item.moduleContent = []
          }
        }
      })
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.computerId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
      setAssetInfo(infos.find(item => item.id === '2'))
      setTerminalInfo(infos.find(item => item.id === '6'))
      setPartnerInfo(infos.find(item => item.id === '5'))
      setDigitalInfo(infos.find(item => item.id === '3'))
      setInfiniteInfo(infos.find(item => item.id === '4'))
      setProductInfo(infos.find(item => item.id === '1'))
    })
  }, [])

  const clickMenu = index => {
    setActiveMenu(index)
    const { fullpageApi } = fullpageRef.current
    switch (index) {
      case 0:
        fullpageApi.moveTo(1)
        break
      case 1:
        fullpageApi.moveTo(2)
        break
      case 2:
        fullpageApi.moveTo(3)
        break
      case 3:
        fullpageApi.moveTo(4)
        break
      case 4:
        fullpageApi.moveTo(6)
        break
      case 5:
        fullpageApi.moveTo(7)
        break
      case 6:
        fullpageApi.moveTo(8)
        break
    }
  }

  const handleLeave = (origin, destination) => {
    switch (destination.anchor) {
      case '1':
        setActiveMenu(0)
        break
      case '2':
        setActiveMenu(1)
        break
      case '3':
        setActiveMenu(2)
        break
      case '4':
        setActiveMenu(3)
        break
      case '5':
        setActiveMenu(3)
        break
      case '6':
        setActiveMenu(4)
        break
      case '7':
        setActiveMenu(5)
        break
      case '8':
        setActiveMenu(6)
        break
    }
  }

  const handleLoading = () => {
    setLoading(false)
    fullpageRef.current.fullpageApi.setAllowScrolling(true)
  }

  return (
    <div className="pc-container">
      {loading && (
        <div className="loading-super">
          <video 
            className="video-loading"
            src={loadingVideo.videoAddress}
            autoPlay
            muted
            onEnded={handleLoading}
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
      )}
      <div className="header-super">
        <img 
          className="header-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => fullpageRef.current.fullpageApi.moveTo(1)}
        />
        <div className="header-content">
          <ul className="menu-list">
            {menus.map((item, index) => (
              <li 
                className={`menu-item ${activeMenu === index ? 'active' : ''}`} 
                key={index}
                onClick={() => clickMenu(index)}
              >{item.text}</li>
            ))}
          </ul>
          <div className="explore" onClick={() => {
            Toast.show({
              icon: <CalendarOutline />,
              content: '即将开启~',
            })
          }}>探索街区</div>
        </div>
      </div>
      {activeMenu !== 0 && <div className="header-mask"></div>}
      <div className="float-super">
        <div className="receive-info-list">
          {activeMenu !== 0 && <div className="info-item top" onClick={() => fullpageRef.current.fullpageApi.moveTo(1)}></div>}
          <div className="info-item info" onClick={() => setShowModal(true)}>
            <div className="info-content">
              <div className="title">业务咨询</div>
              <div className="subtitle">获取解决方案和报价</div>
            </div>
          </div>
          <div className="info-item gzh">
            <div className="gzh-content">
              <div className="title">扫一扫</div>
              <div className="subtitle">关注公众号</div>
              <img className="qr-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-gzh.png" />
            </div>
          </div>
          <div className="info-item consultant">
            <div className="consultant-content">
              <div className="title">扫码咨询</div>
              <div className="subtitle">9:00-18:00</div>
              <img className="qr-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qr-consultant.png" />
            </div>
          </div>
          <div className="info-item chatgpt" onClick={() => window.open('https://qy.himymeta.com/?chatgpt')}>
            <div className="info-content">ChatGPT</div>
          </div>
          {activeMenu !== 6 && <div className="info-item mouse"></div>}
        </div>
        {/* {showMouse && (
          <div className="mouse-icon-super">
            <img className="mouse-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mouse-icon.png" />
            <img className="mouse-arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mouse-arrow-icon.png" />
          </div>
        )} */}
      </div>
      <ReactFullpage
        ref={fullpageRef}
        anchors={['1', '2', '3', '4', '5', '6', '7', '8']}
        licenseKey='264B4850-5ADD4213-AE22D651-FFF0054C'
        onLeave={handleLeave}
        autoScrolling
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Videos loading={loading} active={activeMenu === 0} videos={bannerVideos} />
              </div>
              <div className="section">
                <Asset active={activeMenu === 1} info={assetInfo} />
              </div>
              <div className="section">
                <Digital info={digitalInfo} />
              </div>
              <div className="section">
                <Infinite info={infiniteInfo} />
              </div>
              <div className="section">
                <Terminal info={terminalInfo} />
              </div>
              <div className="section">
                <Nft />
              </div>
              <div className="section">
                <Product info={productInfo}/>
              </div>
              <div className="section">
                <Partner fullpageApi={fullpageApi} info={partnerInfo} menus={menus} />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <Collection visible={showModal} onClose={() => setShowModal(false)} />
    </div>
  )
}

export default Home
