/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 17:44:28
 */
import React, { useState, useRef, useEffect } from 'react'
import { navMenus } from '/libs/constant'
import { Toast, Mask } from 'antd-mobile'
import { CalendarOutline } from 'antd-mobile-icons'
import api from '/api'
import './index.less'

function Message({ history, match }) {
  const [tabs] = useState(['企业介绍', '联系我们'])
  const [showModal, setShowModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [menus, setMenus] = useState([])
  const videoRef = useRef()

  useEffect(() => {
    switch (match.params.slug) {
      case 'introduce':
        setActiveIndex(0)
        break;
      case 'contact':
        setActiveIndex(1)
        break;
    }
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '1')
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.computerId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
    })
  }, [])

  const clickMenu = index => {
    switch (index) {
      case 0:
        history.push('/computer#1')
        break
      case 1:
        history.push('/computer#2')
        break
      case 2:
        history.push('/computer#3')
        break
      case 3:
        history.push('/computer#4')
        break
      case 4:
        history.push('/computer#5')
        break
      case 5:
        history.push('/computer#7')
        break
      case 6:
        history.push('/computer#8')
        break
    }
  }

  const handlePlay = () => {
    setShowModal(true)
    videoRef.current.play()
  }

  const handleClose = () => {
    setShowModal(false)
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }
  
  return (
    <div className="pc-container">
      <div className="header-super">
        <img 
          className="header-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={() => history.push('/computer#1')}
        />
        <div className="header-content">
          <ul className="menu-list">
            {menus.map((item, index) => (
              <li 
                className="menu-item"
                key={index}
                onClick={() => clickMenu(index)}
              >{item.text}</li>
            ))}
          </ul>
          <div className="explore" onClick={() => {
            Toast.show({
              icon: <CalendarOutline />,
              content: '即将开启~',
            })
          }}>探索街区</div>
        </div>
      </div>
      <div className="header-mask"></div>
      <div className="page-block">
        <div className="tabs-super">
          <ul className="tab-menu-list">
            {tabs.map((text, index) => (
              <li 
                className={`tab-menu-item ${activeIndex === index ? 'active' : ''}`} 
                key={index}
                onClick={() => setActiveIndex(index)}
              >
                {text}
                <img className="menu-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/intro-arrow.png" />
              </li>
            ))}
          </ul>
          {activeIndex === 0 && (
            <div className="tab-content company">
              <div className="left-super">
                <div className="img-super">
                  <img className="company-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/company-intro-view.png" />
                  <img 
                    className="bg-icon" 
                    src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-bg-icon.png" 
                    onClick={handlePlay}
                  />
                </div>
              </div>
              <div className="text-super">
                <div className="text-block">北京启元天地网络信息科技有限公司，是北京全时天地在线网络信息股份有限公司全资子公司，成立于2021年11月29日，位于北京城市副中心通州紫光科技园。公司凭借实景影棚、数字影棚、专业级光、动捕设备、360度虚拍系统等多元化虚拟内容制作体系，致力于为企业级客户打造集合虚拟数字资产创造、商业化应用、资产管理、资产增值等可全场景虚拟现实空间无缝链接的商业服务生态闭环。</div>
                <div className="text-block">未来，启元天地将助力企业在多维场景中打造品牌“新名片”，以内容、技术、人才，以及产业生态共同发力的多边发展格局，以虚拟与现实交互的数智化服务，帮助更多品牌沉淀虚拟数字资产，实现Web3.0时代下的品牌价值升级。</div>
              </div>
            </div>
          )}
          {activeIndex === 1 && (
            <div className="tab-content contact">
              <div className="address">北京</div>
              <div className="en-address">BEIJING</div>
              <div className="name">北京启元天地网络信息科技有限公司</div>
              <div className="info">
                <div>公司电话：010-53565500</div>
                <div>公司网址：http://himymeta.com</div>
                <div>办公地址：北京市通州区商通大道5号院通州紫光科技园21号楼</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer-super">
        <div className="logo-content">
          <img 
            className="logo-td" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/td-logo.png" 
            onClick={() => window.open('http://www.372163.com/')}
          />
          <div className="logo-devide"></div>
          <img 
            className="logo-qy" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qy-logo.png" 
            onClick={() => history.push('/computer#1')}
          />
        </div>
        <div className="message-content">
          <div className="menu-list">
            <div className="menu-item" onClick={() => history.push('/computer#2')}>{menus[1] && menus[1].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer#4')}>{menus[3] && menus[3].text}</div>
            <div className="menu-item" onClick={() => history.push('/computer#6')}>{menus[4] && menus[4].text}</div>
            <div className="menu-item" onClick={() => setActiveIndex(0)}>关于我们</div>
            <div className="menu-item" onClick={() => setActiveIndex(1)}>联系我们</div>
          </div>
          <div className="license-list">
            <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
            <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
              <img 
                className="police-logo" 
                src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
              />
              京公网安备 11011202003539号
            </div>
          </div>
        </div>
      </div>
      <Mask
        className="video-modal-super"
        visible={showModal}
        style={{ '--z-index': 1000 }}
        forceRender
      >
        <div className="modal-content">
          <img 
            className="close-icon" 
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/close-icon.png" 
            onClick={handleClose}
          />
          <video
            className="video-player"
            src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/qy-video-8.mov"
            loop
            controls
            ref={videoRef}
          >您的浏览器不支持 video 标签。</video>
        </div>
      </Mask>
    </div>
  )
}

export default Message
