/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 13:38:40
 */
import React, { useState, useRef } from 'react'
import { Swiper, Mask } from 'antd-mobile'

function Videos({ videos }) {
  const [showVideo, setShowVideo] = useState(false)
  const [activeVideo, setActiveVideo] = useState(0)
  const videoRef = useRef(null)
  const swiperRef = useRef(null)

  const handleSwiper = index => {
    setActiveVideo(index)
    setShowVideo(true)
    videoRef.current.load()
  }

  const handleClose = () => {
    setShowVideo(false)
    videoRef.current.pause()
    videoRef.current.currentTime = 0
  }

  const swiperItems = videos.map((item, index) => (<Swiper.Item key={index}>
    <div className="swiper-content" onClick={() => handleSwiper(index)}>
      <img className="swiper-img" src={item.mainPic} />
      <img className="swiper-title" src={item.logoPic} />
      <img className="play-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/swiper-play.png" />
    </div>
  </Swiper.Item>));

  return (
    <div className="swiper-super" id="home">
      <Swiper ref={swiperRef} loop >
        {swiperItems}
      </Swiper>
      <div 
        className="button-box left"
        onClick={() => swiperRef.current.swipePrev()}
      >
        <img className="button-arrow prev" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/swiper-arrow.png" />
      </div>
      <div 
        className="button-box right"
        onClick={() => swiperRef.current.swipeNext()}
      >
        <img className="button-arrow next" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/swiper-arrow.png" />
      </div>
      <img className="next-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/next-icon.png" />
      <img className="block-devide-1" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-1.png" />
      <Mask 
        className="video-modal-super"
        visible={showVideo} 
        onMaskClick={handleClose}
        style={{ '--z-index': 1003 }}
        forceRender
        getContainer={document.getElementById('mobileContainer')}
      >
        <video 
          className="video-player"
          src={videos[activeVideo] && videos[activeVideo].videoAddress}
          loop
          autoPlay={showVideo}
          ref={videoRef}
          controls
        >
          您的浏览器不支持 video 标签。
        </video>
      </Mask>
    </div>
  )
}

export default Videos
