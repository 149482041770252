/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 08:26:18
 */

import React from "react";
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'

function App () {
  return (
    <Router>
      <Routes/>
    </Router>
  );
}

export default App;
