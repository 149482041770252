/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 08:26:18
 */
import React from "react"
import ReactDOM from "react-dom"
import App from './App'
import "./styles.less"
import './libs/global'

// 设置rem基础值
// const html = document.querySelector('html');    
// const width = html.getBoundingClientRect().width;
// html.style.fontSize = width/20 + 'px';
const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
if (isMobile) {
  (function () {
    function setRootFontSize() {
        let dpr, rem, scale, rootWidth;
        const rootHtml = document.documentElement;
    
        // dpr = window.devicePixelRatio || 1; //移动端必须设置

        //限制展现页面的最小宽度
        rootWidth = rootHtml.clientWidth < 320 ? 320 : rootHtml.clientWidth;
        rem = rootWidth / 3.75; // 19.2 = 设计图尺寸宽1920 / 100（设计图的rem = 100）
        // scale = 1 / dpr;
    
        // 设置viewport，进行缩放，达到高清效果 (移动端添加)
        // let vp = document.querySelector('meta[name="viewport"]');
        // vp.setAttribute('content', 'width=' + dpr * rootHtml.clientWidth + ',initial-scale=' + scale + ',maximum-scale=' + scale + ', minimum-scale=' + scale + ',user-scalable=no');
    
        // 动态写入样式
        rootHtml.style.fontSize = `${rem}px`;
    }
    setRootFontSize();
    window.addEventListener("resize", setRootFontSize, false);
    window.addEventListener("orientationchange", setRootFontSize, false); //移动端
  })();
} else {
  (function () {
    function setRootFontSize() {
        let rem, rootWidth;
        const rootHtml = document.documentElement;
        rootWidth = rootHtml.clientWidth
        //限制展现页面的最小宽度
        if (rootWidth < 1280) {
          rootWidth = 1280
        }
        //限制展现页面的最大宽度
        if (rootWidth > 1920) {
          rootWidth = 1920
        }
        // 19.2 = 设计图尺寸宽1920 / 100（ 设计图的1rem = 100px ）
        rem = rootWidth / 19.2;
        // 动态写入样式
        rootHtml.style.fontSize = `${rem}px`;
    }
    setRootFontSize();
    window.addEventListener("resize", setRootFontSize, false);
  })();
}

ReactDOM.render(
  <App/>,
  document.getElementById('app')
);

// const container = document.getElementById('app');
// const root = createRoot(container);
// root.render(<App/>);
