/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-17 09:13:43
 */
import React, { useState, useEffect } from 'react'
import { navMenus, storageKey } from '/libs/constant'
import { throttle } from '/libs/util'
import { Mask } from 'antd-mobile'
import Videos from './components/Videos'
import Asset from './components/Asset'
import Digital from './components/Digital'
import Infinite from './components/Infinite'
import Terminal from './components/Terminal'
import Product from './components/Product'
import Nft from './components/Nft'
import api from '/api'
import './index.less'

function Home({ history, location }) {
  const { state } = location
  const [showAsset, setShowAsset] = useState(false)
  const [showDigital, setShowDigital] = useState(false)
  const [showInfinite, setShowInfinite] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingVideo, setLoaddingVideo] = useState({})
  const [bannerVideos, setBannerVideos] = useState([])
  const [assetInfo, setAssetInfo] = useState({})
  const [terminalInfo, setTerminalInfo] = useState({})
  const [digitalInfo, setDigitalInfo] = useState({})
  const [infiniteInfo, setInfiniteInfo] = useState({})
  const [productInfo, setProductInfo] = useState({})
  const [menus, setMenus] = useState([])

  useEffect(() => {
    const key = sessionStorage.getItem(storageKey)
    if (key) {
      setLoading(false)
    } else {
      sessionStorage.setItem(storageKey, 'loaded')
    }
    if (state) {
      scrollToAnchor(state.id)
    }
    window.addEventListener('scroll', scrollHandle, true);
    api.getVideoList().then(res => {
      const videos = res.filter(item => item.channel !== '1')
      const bannerData = videos.filter(item => item.module === '2').sort((a, b) => b.weight - a.weight)
      const gifData = videos.find(item => item.module === '1' && item.videoAddress.indexOf('.gif') > -1)
      setLoaddingVideo(gifData)
      setBannerVideos(bannerData)
    })
    api.getSectionList().then(res => {
      const infos = res.filter(item => item.channel === '2')
      infos.forEach(item => {
        if (item.moduleContent !== '') {
          try {
            item.moduleContent = JSON.parse(item.moduleContent)
          } catch (err) {
            item.moduleContent = []
          }
        }
      })
      infos.forEach(item => {
        navMenus.forEach(menu => {
          if (menu.mobileId === item.id) {
            menu.text = item.module
          }
        })
      })
      setMenus(navMenus)
      setAssetInfo(infos.find(item => item.id === '8'))
      setTerminalInfo(infos.find(item => item.id === '12'))
      setDigitalInfo(infos.find(item => item.id === '9'))
      setInfiniteInfo(infos.find(item => item.id === '10'))
      setProductInfo(infos.find(item => item.id === '7'))
    })
    // 解决XR微信小程序返回问题 20230327
    // pushHistory()
    // window.addEventListener('popstate', wxNavigateBack, false)

    window.history.pushState('forward', null, '#')
    window.history.forward(1)
   
   // 不知为何必须要加以上两行代码才能成功监听到回退事件..先打个问号??? 后续更新
    window.addEventListener('popstate', wxNavigateBack, false)

    return () => {
      window.removeEventListener('scroll', scrollHandle, true)
      window.removeEventListener('popstate', wxNavigateBack, true)
    }
  }, [])

  // const pushHistory = () => {
  //   const state = {
  //     title: "title",
  //     url: "#"
  //   }
  //   window.history.pushState(state, state.title, state.url)
  // }

  // const wxNavigateBack = evt => {
  //   //首页点击返回,直接关闭网页
  //   WeixinJSBridge.call('closeWindow')
  //   wx.miniProgram.navigateBack() //返回 
  // }

  const wxNavigateBack = evt => {
    // 跳转到上一个小程序页面
    wx.miniProgram.navigateBack({
        delta: 1
    })
  }

  const setAnimation = (id, callback) => {
    const ele = document.getElementById(id)
    if (ele) {
      const eleOffset = ele.getBoundingClientRect()
      // 进入可视区域
      if (eleOffset.top <= (window.innerHeight - eleOffset.height / 2) && eleOffset.bottom >= 0) {
        callback(true)
      } else {
        callback(false)
      }
    }
  }

  const scrollThrottle = () => {
    setAnimation('assets', setShowAsset)
    setAnimation('digital', setShowDigital)
    setAnimation('infinite', setShowInfinite)
  }

  const scrollHandle = throttle(scrollThrottle, 200, this, true)

  // 滚动到顶部
  const scrollToTop = () => {
    let scrollTimer = setInterval(() => {
      const  scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const ispeed = Math.ceil(scrollTop / 6)
      if(scrollTop <= 0){
        clearInterval(scrollTimer)
        scrollTimer = null
      } else {
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop - ispeed
      }
    },30)
  }

  const scrollToAnchor = id => {
    const anchorElement = document.getElementById(id)
    if(anchorElement) { 
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }

  const clickMenu = name => {
    scrollToAnchor(name)
    setShowMenu(false)
  }

  return (
    <div className="mobile-container" id="mobileContainer">
      <img 
        className="icon-chatgpt" 
        src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-chatgpt-mobile.png"
        onClick={() => window.open('https://qy.himymeta.com/?chatgpt')}
      />
      {loading && (
        <div className="loading-super">
          <img 
            className="video-loading" 
            src={loadingVideo.videoAddress}
            onLoad={() => {
              setTimeout(() => {
                setLoading(false)
              }, 3500)
            }}
          />
        </div>
      )}
      <div className="nav-super">
        <img 
          className="nav-logo" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/header-logo.png" 
          onClick={scrollToTop}
        />
        <img 
          className={`nav-button ${showMenu ? 'close' : ''}`}
          src={`https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/${showMenu ? 'mobile-close-img' : 'nav-button'}.png`}
          onClick={toggleMenu}
        />
      </div>
      <div className="bottom-super">
        <div>定制您的专属方案！</div>
        <div className="contact-button" onClick={() => history.push('/mobile/collection')}>联系我们</div>
      </div>
      <Videos videos={bannerVideos} />
      <Asset showAnimation={showAsset} info={assetInfo} />
      <Digital showAnimation={showDigital} info={digitalInfo} />
      <Infinite showAnimation={showInfinite} info={infiniteInfo} />
      <Terminal info={terminalInfo} />
      <Nft />
      <Product info={productInfo} />
      <div className="mobile-bottom-nav">
        <ul className="nav-list">
          <li className="nav-item" onClick={() => scrollToAnchor('assets')}>
            <div>{menus[1] && menus[1].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => scrollToAnchor('infinite')}>
            <div>{menus[3] && menus[3].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => scrollToAnchor('nft')}>
            <div>{menus[4] && menus[4].text}</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'introduce' })}>
            <div>关于我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
          <li className="nav-item" onClick={() => history.push('/mobile/introduce', { id: 'contact' })}>
            <div>联系我们</div>
            <img className="nav-arrow" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
          </li>
        </ul>
      </div>
      <div className="mobile-footer">
        <div className="license-list">
          <div className="license-item" onClick={() => window.open('https://beian.miit.gov.cn/')}>京ICP备2021041162号-2</div>
          <div className="license-item" onClick={() => window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539')}>
            <img 
              className="police-logo" 
              src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/police-logo.png" 
            />
            京公网安备 11011202003539号
          </div>
        </div>
        <img 
          className="totop-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-totop-icon.png" 
          onClick={scrollToTop}
        />
      </div>
      <div className="bottom-blank"></div>
      <Mask 
        className="menu-modal-super"
        visible={showMenu} 
        onMaskClick={() => setShowMenu(false)}
        style={{ '--z-index': 1001 }}
      >
        <ul className="menu-list">
          {menus.map((item, index) => (
            <li className="menu-item" key={index} onClick={() => clickMenu(item.name)}>
              {item.text}
              <img className="arrow-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-nav-arrow.png" />
            </li>
          ))}
        </ul>
      </Mask>
    </div>
  )
}

export default Home
