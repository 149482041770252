/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 16:12:45
 */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import Mobile from './pages/Mobile'
import Computer from './pages/Computer'
import NoMatch from './pages/404'

export default () => {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to={isMobile ? '/mobile' : '/computer'} push />} />   
            <Route path='/computer' component={Computer} />
            <Route path="/mobile" component={Mobile} />
            <Route component={NoMatch} />
        </Switch>
    )
}
