/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 11:33:52
 */
import React, { useState, useRef, useEffect } from 'react'

function Videos({ loading, active, videos }) {
  const [activeVideo, setActiveVideo] = useState(0)
  const [progressWidth, setProgressWidth] = useState(0)
  const [showAudioPause, setShowAudioPause] = useState(true)
  const [playPromise, setPlayPromise] = useState(null)
  const videoRef = useRef()

  useEffect(() => {
    if (active && !loading) {
      setPlayPromise(videoRef.current.play())
    } else {
      // playPromise确保视频可以安全暂停
      if (playPromise !== null) {
        playPromise.then(() => {
          videoRef.current.pause()
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }, [active, loading])

  const handlePrev = () => {
    if (activeVideo > 0) {
      setActiveVideo(activeVideo - 1)
    } else {
      setActiveVideo(videos.length - 1)
    }
  }

  const handleNext = () => {
    if (activeVideo < videos.length - 1) {
      setActiveVideo(activeVideo + 1)
    } else {
      setActiveVideo(0)
    }
  }

  const handleProgress = evt => {
    const num = Math.ceil(evt.target.currentTime/evt.target.duration * 100)
    if (isNaN(num)) {
      setProgressWidth(0)
    } else {
      setProgressWidth(num + '%')
    }
  }

  const handleAudio = () => {
    if (showAudioPause) {
      videoRef.current.muted = false
      setShowAudioPause(false)
    } else {
      videoRef.current.muted = true
      setShowAudioPause(true)
    }
  }

  return (
    <div className="page-index">
      <video 
        className="section-bg"
        src={videos[activeVideo] && videos[activeVideo].videoAddress}
        poster={videos[activeVideo] && videos[activeVideo].mainPic}
        preload="auto"
        autoPlay={!loading}
        muted
        onTimeUpdate={handleProgress}
        onEnded={handleNext}
        ref={videoRef}
      >
        您的浏览器不支持 video 标签。
      </video>
      <div className="index-cover"></div>
      <div className="audio-play-super" onClick={handleAudio}>
        <img 
          className="audio-play-icon" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/audio-play-icon.png" 
        />
        <img 
          className={`audio-pause-icon ${showAudioPause ? 'show' : ''}`}
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/audio-pause-icon.png" 
        />
      </div>
      <div className="control-super">
        <img 
          className="arrow-icon left-arrow" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/tab-arrow.png" 
          onClick={handlePrev}
        />
        <div className="button-super">
          <img className="button-bg" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/tab-line.png" />
          <ul className="button-list">
            {videos.map((item, index) => (
              <li 
                className={`button-item ${activeVideo === index ? 'active' : ''}`} 
                key={index}
                onClick={() => setActiveVideo(index)}
              >
                <img className="button-img" src={item.logoPic} />
                <img className="play-icon" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/play-icon.png" />
                <img className="video-play" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/video-play.gif" />
              </li>
            ))}
          </ul>
        </div>
        <img 
          className="arrow-icon right-arrow" 
          src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/tab-arrow.png" 
          onClick={handleNext}
        />
      </div>
      <div className="dots-super">
        <ul className="dots-list">
          {videos.map((item, index) => (
            <li 
              className={`dots-item ${activeVideo === index ? 'active' : ''}`} 
              key={index}
            >
              <div className="play-progress" style={{ width: progressWidth }}></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Videos
