import React from 'react'
import { Result } from 'antd-mobile'
import { Link } from 'react-router-dom'

const NoMatch = () => (
    <Result
        status="warning"
        title="抱歉, 这个页面不存在"
        description={<Link to="/">回到首页</Link>}
        style={{ height: '100vh' }}
    />
)

export default NoMatch
