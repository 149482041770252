/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-23 17:30:52
 */
/**
 * 接口文件
 * url的第一个反斜杠/一定要加，否则请求会带上相对路径
 */

 const prefix = '/api'
 const urls   = {
  getToken: {
    url: '/web/index.php?r=api/index/get-csrf-token',
    method: 'get'
  },
  saveContact: {
    url: '/web/index.php?r=api/index/save-contact',
    method: 'post'
  },
  getAdvList: {
    url: '/web/index.php?r=api/public/adv-list',
    method: 'get'
  },
  getVideoList: {
    url: '/web/index.php?r=api/public/video-list',
    method: 'get'
  },
  getSectionList: {
    url: '/web/index.php?r=api/public/cont-indx-list',
    method: 'get'
  },
 }
 
 for (let k in urls) {//eslint-disable-line
   urls[k].url = prefix + urls[k].url
 }
 
 export default urls