/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-13 14:09:12
 */
import React from 'react'

function Infinite({ showAnimation, info }) {
  return (
    <div className="mobile-block infinite" id="infinite" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <img className="block-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/infinite-block-title.png" />
      <div className="infinite-list">
        {info.moduleContent && info.moduleContent.map((item, index) => (
          <div 
            className={`infinite-item item-${index + 1} ${showAnimation ? 'appear' : ''}`}
            key={index}
            style={{ backgroundImage: `url(${item.pic})` }}
          >
            <div className="item-intro">{item.title}</div>
          </div>
        ))}
      </div>
      <img className="block-devide-2" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/block-devide-2.png" />
    </div>
  )
}

export default Infinite
