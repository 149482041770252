/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 13:52:47
 */
import React, { useState, useEffect } from 'react'

function Asset({ active, info }) {
  const [showAnimation, setShowAnimation] = useState(false)

  useEffect(() => {
    setShowAnimation(active)
  }, [active])

  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-assets-title.png" />
        <div className="assets-subtitle" dangerouslySetInnerHTML={{ __html : info.moduleSubtitle }}></div>
      </div>
      <div className="animate-super">
        <img className="assets-car" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-assets-car.png" />
        <img className="assets-woman" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-assets-woman.png" />
        <img className={`assets-box-1 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-box-bg-1.png" />
        <img className={`assets-box-2 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-box-bg-2.png" />
        <img className={`assets-box-3 ${showAnimation ? 'twinkle' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-box-bg-3.png" />
        <img className={`assets-img-1 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-img-1.png" />
        <img className={`assets-img-2 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-img-2.png" />
        <img className={`assets-img-3 ${showAnimation ? 'appear' : ''}`} src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/assets-img-3.png" />
      </div>
    </div>
  )
}

export default Asset
