/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-04-06 14:20:22
 */
import React from 'react'

function Terminal({ info }) {
  return (
    <div className="section-block" style={{ backgroundImage: `url(${info.backgroundPic})` }}>
      <div className="product-content">
        <img className="product-title" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-terminal-title.png" />
        <div className="mobile-super">
          <div className="intro" dangerouslySetInnerHTML={{ __html : info.moduleSubtitle }}></div>
          <img className="mobile-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/terminal-mobile.png" />
          <div className="name">移动端(Android/ios)</div>
        </div>
        <div className="pc-vr-super">
          <div className="pc-type">
            <img className="pc-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/terminal-pc.png" />
            <div className="name">PC端</div>
          </div>
          <div className="vr-type">
            <img className="vr-img" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/terminal-vr.png" />
            <div className="name">VR端</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terminal
